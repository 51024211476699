import { render, staticRenderFns } from "./editSubject.vue?vue&type=template&id=3cf9490c&scoped=true&"
import script from "./editSubject.vue?vue&type=script&lang=js&"
export * from "./editSubject.vue?vue&type=script&lang=js&"
import style0 from "./editSubject.vue?vue&type=style&index=0&id=3cf9490c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cf9490c",
  null
  
)

export default component.exports