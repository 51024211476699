<template>
    <div>
        <a-modal
            :title="!isEdit?'添加章节':'编辑章节'"
            :visible="visible"
            :confirmLoading ="loading"
            @cancel="visible = false"
            destroyOnClose
            @ok="submit"
        >
            <a-form v-bind="formItemLayout" :form="form">
                <a-form-item label="章节名称">
                    <a-input  v-decorator="['name', {initialValue: filter.name, rules: [{ required: true, message: '请输入章节名!' }] }]" placeholder="请输入章节名" />
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal
            :title="!isEdit?'添加章节':'编辑章节'"
            :visible="video.show"
            :confirmLoading ="loading"
            @cancel="video.show = false"
            destroyOnClose
            @ok="submitVideo"
        >
            <a-form v-bind="formItemLayout" :form="formVideo">
                <a-form-item label="视频名称">
                    <a-input  v-decorator="['vedio_name', {initialValue: video.filter.vedio_name, rules: [{ required: true, message: '请输入视频名称!' }] }]" placeholder="请输入视频名称" />
                </a-form-item>
                <a-form-item label="视频地址">
                    <a-input  v-decorator="['vedio_url', {initialValue: video.filter.vedio_url, rules: [{ required: true, message: '请输入视频地址!' }] }]" placeholder="请输入视频地址" />
                </a-form-item>
                <a-form-item label="上传讲义" >
                    <a-upload
                        name="file"
                        :accept="'.PDF'"
                        :customRequest="customRequest"
                        @change="handleChange"
                        :fileList="video.coursewareFile"
                    >
                        <a-button> <a-icon type="upload" />点击上传</a-button>
                    </a-upload>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-drawer
            title="排序"
            placement="right"
            :closable="false"
            :visible="dragVisible"
            @close="onClose"
            width="400"
            >
            <div class="dragTable">
            <draggable handle=".dragIcon" :list="draggableList" v-if="draggableList.length" @change="log">
                <div v-for="(item, index) in draggableList" :key="index" class="dragWrap">
                    <div class="dragBody border" >
                        <div class="iconTh">
                            <i class="dragIcon"></i>
                        </div>
                        <div class="flex1">{{item.name}}</div>
                    </div>
                </div>
            </draggable>
            <div v-else style="padding:20px 0;text-align:center;background:#fff">
                暂无数据
            </div>
        </div>
        </a-drawer>
        <div class="filter">
            <a-space>
                <a-button type="primary" @click="addItem">添加章节</a-button>
                <a-button type="primary" @click="openDrawer">排序</a-button>
            </a-space>
        </div>
        <a-table :columns="columns" rowKey="id" :dataSource="result" style="margin-top:20px" @expand="expand" :expandedRowKeys="expandedRowKeys" class="table">
            <template slot="option" slot-scope="text,record">
                <i class="z-icon editB" @click="editItem(record)"></i>
                <i class="z-icon delete" @click="deleteItem(record)"></i>
                <a-icon type="plus-circle" v-if="record.cate_id" class="plus-icon" @click="addVideo(record)"/>
            </template>
        </a-table>
    </div>
</template>
<script>
const columns = [
    { title: '章节', dataIndex: 'name' },
    { title: '视频名称', dataIndex: 'vedio_name' },
    { title: '讲义名称', dataIndex: 'courseware_name'},
    { title: '操作', dataIndex: 'option', scopedSlots: { customRender: 'option' }, width: 260, align: 'center' },
]
import * as http from '@/libs/examapi'
import { getTaskList, uploadImg } from '@/libs/api'
export default {
    props:['id', 'type'],
    name: 'videoList',
    data(){
        return{
            columns,
            expandedRowKeys:[],
            result: [],
            isEdit: false,
            visible: false,
            //form
            form: this.$form.createForm(this),
            formVideo: this.$form.createForm(this),
            loading: false,
            formItemLayout: {
                labelCol: { span: 4 },
                wrapperCol: { span:18 },
            },
            filter:{
                name: '',
            },
            item:{},
            exams:[],
            //添加视频
            video:{
                show: false,
                item: {},
                filter: {
                    vedio_name: '',
                    courseware_name: '',
                    vedio_url: '',
                    courseware_url: ''
                },
                videoFile:[],
                coursewareFile:[]
            },
            fileList:[],
            //排序
            dragVisible: false,
            draggableList: []
        }
    },
    watch:{
        id(val){
            this.getExams()
            this.getData()
        },
        type(val){
            if(val){
                this.getData()
            }
        }
    },
    mounted(){
        this.getExams()
        this.getData()
    },
    methods:{
        getExams(){
            getTaskList({cate_id: this.id,user_id:this.$store.state.userInfo.id}).then(res => {
                this.exams = res.list
            })
        },
        getData(){
            this.result = []
            http.getExam({cate_id: this.id, type: this.type}).then(res => {
               this.result = res
               this.result.forEach(el => {
                   if(!el.children.length){
                       delete el.children
                   }
               })
            }).catch(error=>{
                console.log(error)
            })
        },
        submit(e){
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    if(!this.isEdit){
                        let request = {...values, type: this.type, cate_id: this.id}
                        http.addExam(request).then(res => {
                            this.visible = false
                            this.getData()
                        })
                    }else{
                        let request = {...this.filter, ...values, type: this.type, cate_id: this.id, id: this.item.id}
                        console.log(request)
                        http.editExam(request).then(res => {
                            this.visible = false
                            this.getData()
                        })
                    }
                }
            });

        },
        addItem(){
            this.filter = {
                name: '',
            }
            this.isEdit = false ; 
            this.visible = true 
        },
        editItem(item){
            this.isEdit = true
            if(item.cate_id){
                this.item = item
                this.filter.name = item.name
                this.visible = true
            }else{
                this.video.filter = {...item}
                this.video.show = true
                if(item.courseware_name){
                    this.video.coursewareFile = [{
                        uid: '-1',
                        name: item.courseware_name,
                        status: 'done',
                        url: item.courseware_url,
                    }]
                }else{
                    this.video.coursewareFile = []
                }
                this.video.item = item
            }
        },
        deleteItem({id, vedio_name, name}){
            let _this = this
            this.$confirm({
                title: vedio_name?`确定删除视频-${vedio_name}？`:`确定删除章节-${name}？`,
                centered:true,
                okText:'确定',
                cancelText:'取消',
                onOk() {
                    if(vedio_name){
                        http.delStudyHelp({id}).then(res => {
                            _this.getData()
                        })
                    }else{
                        _this.$message.info('删除章节缺少接口')
                    }
                },
            });
        },
        videoRequest(data){
            let formData = new FormData()
            formData.append('file',data.file)
            uploadImg(formData).then(res=>{
               this.video.videoFile[0].status='done'
               this.video.filter.vedio_url = res.fileUrl
             }).catch(error =>{
                console.log(error)
                this.video.videoFile[0].status='error'
            })
        },
        videoChange(info){
            let fileList = [...info.fileList];
            if(!fileList.length){
               this.video.filter.vedio_url = ''
            }
            fileList = fileList.slice(-1);
            fileList = fileList.map(file => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
            });
            this.video.videoFile = fileList;
        },
        resetVideo(){
            this.formVideo.resetFields()
            this.video.filter={
                vedio_name: '',
                courseware_name: '',
                vedio_url: '',
                courseware_url: ''
            }
            this.video.coursewareFile = []
        },
        addVideo(data){
            this.resetVideo()
            this.video.show = true
            this.video.item = data
            this.isEdit = false
        },
        submitVideo(e){
            e.preventDefault();
            this.formVideo.validateFields((err, values) => {
                if (!err) {
                    let {filter:{courseware_url, courseware_name}} = this.video
                    if(this.isEdit){
                        let {good_task_id, id} = this.video.item
                        http.editStudyHelp({...values,courseware_name, courseware_url, good_task_id: good_task_id, id}).then(res => {
                            this.video.show = false,
                            this.getData()
                        }).catch(() => {
                            this.isEdit = false
                        })
                    }else{
                        let {id} = this.video.item
                        http.addStudyHelp({...values, courseware_url,courseware_name, good_task_id: id}).then(res => {
                            this.video.show = false,
                            this.getData()
                            this.isEdit = false
                        }).catch(() => {
                            this.isEdit = false
                        })
                    }
                }
            });
        },
        customRequest(data){
            let formData = new FormData()
            formData.append('file',data.file)
            uploadImg(formData).then(res=>{
               this.video.coursewareFile[0].status='done'
               this.video.filter.courseware_name = res.fileName
               this.video.filter.courseware_url = res.fileUrl
            }).catch(error =>{
                console.log(error)
                this.video.coursewareFile[0].status='error'
            })
        },
        handleChange(info){
            let fileList = [...info.fileList];
            if(!fileList.length){
               this.video.filter.courseware_url = ''
               this.video.filter.courseware_name = ''
            }
            fileList = fileList.slice(-1);
            fileList = fileList.map(file => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
            });
            this.video.coursewareFile = fileList;
        },
        expand(expanded, record){
            if(!expanded){
                let index = this.expandedRowKeys.findIndex(el=>el == record.id)
                this.expandedRowKeys.splice(index, 1)
            }else{
                this.expandedRowKeys.push(record.id)
            }
        },
        openDrawer(){
           this.dragVisible = true
           this.draggableList = [...this.result]
        },
        log(){
            this.$message.info('拖拽接口待完成')
            // let result = { ids:this.result.map(el=>el.id).join(',')}
            // http.orderExam(result).then(res=>{
            // }).catch(error=>{
            //     this.$message.error('拖拽失败，请重新操作')
            //     this.getProblems()
            //     console.log(error)
            // })
        },
        onClose(){
            this.dragVisible = false
        }


    }
}
</script>
<style scoped>
.filter{
    background: #fff;
    padding: 5px 20px 10px;
    justify-content: flex-start;
}
.plus-icon{
    font-size: 20px;
    vertical-align: middle;
    color: #ff8a78;
    cursor: pointer;
}
.dragBody{
    background: #f1f2f6;
}
</style>