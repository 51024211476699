<template>
     <a-modal
        :title="edit?'编辑课程视频及讲义':'添加课程视频及讲义'"
        :visible="visible"
        :confirmLoading ="loading"
        @cancel="$emit('close')"
        destroyOnClose
        @ok="submit"
    >
        <a-form
            v-bind="formItemLayout"
            v-if="visible"
            >
            <a-form-item label="课程名">
               <a-input v-model="data.name"  placeholder="请输入课程名" />
            </a-form-item>
            <a-form-item label="视频" v-if="(!edit && currentItem)|| (edit && currentItem.parent_id)">
                <a-input v-model="data.vedio"  placeholder="请输入视频链接" />
            </a-form-item>
            <a-form-item label="讲义" v-if="(!edit && currentItem)|| (edit && currentItem.parent_id)">
                <div style="display:flex">
                     <a-radio-group v-model="data.note_type" @change="changeNoteType">
                        <a-radio :style="radioStyle" :value="1">通用</a-radio>
                        <a-radio :style="radioStyle" :value="2">自定义
                            <!-- <a-input v-model="data.note_content" v-if="data.note_type === 2" :style="{ width: '180px', marginLeft: '10px' }" /> -->
                        </a-radio>
                    </a-radio-group>
                    <div style="padding-top:25px" v-if="data.note_type === 2">
                        <a-upload
                            name="file"
                            :accept="'.PDF'"
                            @change="handleChange"
                            :customRequest="customRequest"
                            :fileList='fileList'
                        >
                            <a-button> <a-icon type="upload" />点击上传</a-button>
                        </a-upload>
                    </div>
                </div>
               
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import * as http from '@/libs/examapi'
import { uploadImg } from '@/libs/api'
export default {
    name: 'addClass',
    props: [ 'visible', 'currentItem', 'sid', 'edit'],
    watch:{
        visible(val){
            if(val){
                this.reset()
                if(this.edit){
                    if(this.currentItem.parent_id){
                        let {name,vedio,note_type,note_content} = this.currentItem
                        this.data= { name,vedio,note_type,note_content }
                    }else{
                        this.data.name = this.currentItem.name
                    }
                   
                }
            }
        }
    },
    data(){
        return{
            fileList:[],
            formItemLayout: {
                labelCol: { span: 4 },
                wrapperCol: { span:18 },
            },
            radioStyle: {
                display: 'block',
                height: '30px',
                lineHeight: '30px',
            },
            loading:false,
            data:{
                name: '',
                vedio: '',
                note_type: 1,
                note_content: ''
            }
        }
    },
    methods:{
        reset(){
            this.data = {
                name: '',
                vedio: '',
                note_type: 1,
                note_content: ''
            }
            this.loading = false
        },
        changeNoteType(e){
            if(e.target.value == 1){
                this.data.note_content = ''
                this.fileList = []
            }
        },
        handleChange(info) {
            let fileList = [...info.fileList];
            fileList = fileList.slice(-1);
            fileList = fileList.map(file => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
            });
            this.fileList = fileList;
        },
        customRequest(data){
            let formData = new FormData()
            formData.append('file',data.file)
            uploadImg(formData).then(res=>{
               this.fileList[0].status='done'
               this.data.note_content = res.fileUrl
            // this.imageUrl=res.fileUrl
            },error=>{
            console.log(error)
            })
        },
        submit(){
            let {name, vedio, note_type, note_content} = this.data
            if(!name){
                this.$message.error('请输入课程名')
                return
            }
            if((!this.edit && this.currentItem)|| (this.edit && this.currentItem.parent_id)){
                if(!vedio){
                    this.$message.error('请输入视频链接')
                    return
                }
                if(note_type == 2 && ! note_content){
                    this.$message.error('请输入自定义讲义')
                    return
                }
            }
            this.loading = true
            let request = null

            if((!this.edit && this.currentItem)|| (this.edit && this.currentItem.parent_id)){
                //子集
                request = this.data
                if(!this.edit){
                    request.sid = this.sid
                }
                request.id = this.currentItem.id
            }else{
                //父级
                if(this.edit){
                    request={
                        id:this.currentItem.id,
                        name
                    }
                }else{
                    request = {
                        sid:this.sid,
                        name
                    }
                }
            }
            if(this.edit){
                http.edit_course(request).then(res=>{
                    this.$emit('close')
                    this.$emit('update')
                    this.loading = false
                }).catch(error=>{
                    console.log(error)
                    this.loading = false
                })
            }else{
                http.add_course(request).then(res=>{
                    this.$emit('close')
                    this.$emit('update')
                    this.loading = false
                }).catch(error=>{
                    console.log(error)
                    this.loading = false
                })
            }
        }
    }
}
</script>
<style lang="less" scoped>

</style>
