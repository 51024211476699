<template>
    <div class="basicSet">
        <a-form :hideRequiredMark="true" v-bind="formItemLayout" :form="form" @submit="handleSubmit">
            <a-form-item label="科目代码">
               <a-input v-model="filter.code" class="w400" placeholder="请输入科目代码" />
            </a-form-item>
            <a-form-item label="商品分类">
                <a-input v-model="goods" class="w400" disabled placeholder="请输入科目代码" />
                <!-- <a-select v-model="filter.good_id" class="w400" placeholder="请选择商品分类">
                    <a-select-option :value="item.id" v-for="(item, index) in goods" :key="index">
                        {{item.name}}
                    </a-select-option>
                </a-select> -->
            </a-form-item>
            <a-form-item label="教材主图" >
                <a-upload
                    name="avatar"
                    list-type="picture-card"
                    :show-upload-list="false"
                    action=""
                    :customRequest="customRequest"
                >
                <img v-if="filter.pic" :src="filter.pic" alt="avatar" />
                <div v-else>
                    <a-icon :type="loading ? 'loading' : 'plus'" style="font-size:30px;color:#dcdcdc"/>
                </div>
            </a-upload>
            </a-form-item>
            <a-form-item label="教材原价" >
                 <a-input-number :precision="2" class="w400" 
                    v-decorator="['cost_price', 
                    { 
                        initialValue: filter.cost_price,  
                        rules: [ { required: true, message: '请输入教材原价!' },
                    ]}]"  placeholder="请输入教材原价" />
            </a-form-item>
            <a-form-item label="教材现价" >
               <a-input-number :precision="2" class="w400" v-decorator="['now_price', 
                    { 
                        initialValue: filter.now_price,  
                        rules: [ { required: true, message: '请输入教材现价!' },
                    ]}]"  placeholder="请输入教材现价" />
            </a-form-item>
            <a-form-item label="购买基数">
               <a-input type="number" class="w400"
                v-decorator="['buy_num', 
                    { 
                        initialValue: filter.buy_num,  
                        rules: [ { required: true, message: '请输入购买基数!' },
                    ]}]"  placeholder="请输入购买基数" />
            </a-form-item>
            <a-form-item label="领航助学">
                <a-space :size="30">
                    <a-checkbox :checked="filter.lh_is_show" @change="(e) => {changeCheckbox(e, 'lh_is_show')}">展示</a-checkbox>
                    <a-radio-group v-model="filter.lh_is_ff" @change="(e) => {onChange(e, 'lh_is_ff')}">
                        <a-radio :value="0">免费</a-radio>
                        <a-radio :value="1">付费</a-radio>
                    </a-radio-group>
                    <a-checkbox v-if="filter.lh_is_ff" :checked="filter.lh_is_jh" @change="(e) => {changeCheckbox(e, 'lh_is_jh')}">金额</a-checkbox>
                    <a-input-number  v-if="filter.lh_is_ff && filter.lh_is_jh" placeholder="金额" v-model="filter.lh_buy_price"></a-input-number>
                    <!-- <div>
                        <span>有效期：</span>
                        <a-input-number placeholder="金额" v-model="filter.zt_buy_price"></a-input-number>
                    </div> -->
                </a-space>
            </a-form-item>
            <a-form-item label="真题汇编">
                <a-space :size="30">
                    <a-checkbox :checked="filter.zt_is_show" @change="(e) => {changeCheckbox(e, 'zt_is_show')}">展示</a-checkbox>
                    <a-radio-group v-model="filter.zt_is_ff" @change="(e) => {onChange(e, 'zt_is_ff')}">
                        <a-radio :value="0">免费</a-radio>
                        <a-radio :value="1">付费</a-radio>
                    </a-radio-group>
                    <a-checkbox v-if="filter.zt_is_ff" :checked="filter.zt_is_jh" @change="(e) => {changeCheckbox(e, 'zt_is_jh')}">金额</a-checkbox>
                    <a-input-number v-if="filter.zt_is_ff && filter.zt_is_jh" placeholder="金额" v-model="filter.zt_buy_price"></a-input-number>
                    <!-- <div>
                        <span>有效期：</span>
                        <a-input-number placeholder="金额" v-model="filter.zt_buy_price"></a-input-number>
                    </div> -->
                </a-space>
            </a-form-item>
            <a-form-item label="十套通关">
                 <a-space :size="30">
                    <a-checkbox :checked="filter.st_is_show" @change="(e) => {changeCheckbox(e, 'st_is_show')}">展示</a-checkbox>
                    <a-radio-group v-model="filter.st_is_ff" @change="(e) => {onChange(e, 'st_is_ff')}">
                        <a-radio :value="0">免费</a-radio>
                        <a-radio :value="1">付费</a-radio>
                    </a-radio-group>
                    <a-checkbox v-if="filter.st_is_ff" :checked="filter.st_is_jh" @change="(e) => {changeCheckbox(e, 'st_is_jh')}">金额</a-checkbox>
                    <a-input-number v-if="filter.st_is_ff && filter.st_is_jh" placeholder="金额" v-model="filter.st_buy_price"></a-input-number>
                    <!-- <div>
                        <span>有效期：</span>
                        <a-input-number placeholder="金额" v-model="filter.zt_buy_price"></a-input-number>
                    </div> -->
                </a-space>
            </a-form-item>
            <a-form-item label="斩题密训">
                <a-space :size="30">
                    <a-checkbox :checked="filter.bg_is_show" @change="(e) => {changeCheckbox(e, 'bg_is_show')}">展示</a-checkbox>
                    <!-- <div>
                        <span>有效期：</span>
                        <a-input-number placeholder="金额" v-model="filter.zt_buy_price"></a-input-number>
                    </div> -->
                    <!-- <a-radio-group v-model="filter.bg_is_buy" @change="(e) => {onChange(e, 'bg_is_buy')}">
                        <a-radio :value="1">免费</a-radio>
                        <a-radio :value="2">付费</a-radio>
                    </a-radio-group> -->
                    <a-checkbox v-if="filter.bg_is_buy" :checked="filter.bg_is_jh" @change="(e) => {changeCheckbox(e, 'bg_is_jh')}">金额</a-checkbox>
                    <a-input-number v-if="filter.bg_is_buy && filter.bg_is_jh" placeholder="金额" v-model="filter.bg_buy_price" addon-after=".com"></a-input-number>
                </a-space>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 4 }">
              <a-button type="primary" class="w400" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </div>
</template>
<script>
import * as http from '@/libs/examapi'
import { uploadImg } from '@/libs/api'
export default {
    name: 'basicSet',
    props:["id"],
    data(){
        return{
            form: this.$form.createForm(this),
            goods: '',
            loading: false,
            waitTime: 0,
            formItemLayout:{
                labelCol: { span: 4 },
                wrapperCol: { span:18 },
            },
            
            filter:{
                code: '',
                pic:'',
                cost_price: '',
                now_price: '',
                buy_num: '',
                lh_buy_price: '',
                zt_buy_price: '',
                st_buy_price: '',
                bg_buy_price: '',
            },
            checks:[
                'lh_is_show','lh_is_jh',
                'zt_is_show','zt_is_jh',
                'st_is_show','st_is_jh',
                'bg_is_show','bg_is_jh',
            ],
            radios:['lh_is_buy','zt_is_buy','st_is_buy','bg_is_buy', ],
            plainOptions: [
                { label: '金额', value: 1 },
            ]
        }
    },
    watch:{
        id(val){
            if(val){
                this.getDetail()
            }
        },
    
    },
    mounted(){
        this.getDetail()
    },
    methods:{
        reset(){
            this.goods = ''
            this.filter={
                code: '',
                pic:'',
                cost_price: '',
                now_price: '',
                buy_num: '',
                lh_is_show: false,
                lh_is_ff: false,
                lh_is_buy: false,
                lh_is_jh: true,
                lh_buy_price: '',

                zt_is_show: true,
                zt_is_ff: false,
                zt_is_buy: false,
                zt_is_jh: true,
                zt_buy_price: '',

                st_is_show: true,
                st_is_ff: false,
                st_is_buy: false,
                st_is_jh: true,
                st_buy_price: '',

                bg_is_show: true,
                bg_is_ff: true,
                bg_is_buy: false,
                bg_is_jh: true,
                bg_buy_price: '',
            }
        },
        getDetail(){
            this.form.resetFields();
            this.reset()
            http.getBasicSet({id:this.id}).then(res => {
                if(res){
                    this.filter = res
                    this.checks.forEach(el =>{
                        this.filter[el] = this.filter[el]?true:false
                    })
                    this.radios.forEach(el =>{
                        this.filter[el] = this.filter[el]? 2: 1
                    })
                    if(res.goods.length){
                        this.goods = res.goods.map(el => el.cname).join(',')
                    }
                }
            })
        },
        customRequest(data){
            let formData = new FormData()
            formData.append('file',data.file)
            this.loading = true
            uploadImg(formData).then(res=>{
                this.loading = false
                this.filter.pic=res.fileUrl
            },error=>{
                console.log(error)
                this.loading = false
            })
        },
        handleSubmit(e){
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    if(!this.filter.pic){
                        this.$message.error('请上传图片')
                        return false
                    }else{
                        let filters = {...this.filter}
                        this.checks.forEach(el =>{
                            filters[el] = filters[el]?1:0
                        })
                        this.radios.forEach(el =>{
                            filters[el] = filters[el]>1? 1:0
                        })
                        let request = {
                            ...filters,
                            ...values,
                            cate_id: this.id
                        }
                        http.addBasicSet(request).then(res => {
                            this.$message.success('提交成功')
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                }
            });
        },
        changeCheckbox(e, type){
            this.filter[type] = e.target.checked
        },
        onChange(e, type){
            this.filter[type] = e.target.value
        }
    }
}
</script>
<style scoped>

.basicSet{
    background: #fff;
    padding: 40px;
}
.w400{
    width: 400px
}
</style>