<template>
     <a-modal
        :title="edit?'编辑题库':'添加题库'"
        :visible="visible"
        :confirmLoading ="loading"
        @cancel="$emit('close')"
        destroyOnClose
        @ok="submit"
    >
        <a-form
            v-bind="formItemLayout"
            v-if="visible"
            >
            <a-form-item :label="currentItem?'试卷名称':'题库名称'">
               <a-input v-model="data.paper_name"  :placeholder="currentItem?'请输入试卷名称':'请输入题库名称'" />
            </a-form-item>
            <a-form-item label="试卷链接" v-if="(!edit && currentItem)|| (edit && currentItem.parent_id)">
                <a-select v-model="data.paper_url" style="width:100%" placeholder="请选择试卷链接">
                    <a-select-option :value="item.id" v-for="(item, index) in testList" :key="index">
                        {{item.name}}
                    </a-select-option>
                </a-select>
                <!-- <a-input v-model="data.paper_url"  placeholder="请输入试卷链接" /> -->
            </a-form-item>                                                        
            <!-- <a-form-item label="讲义" v-if="(!edit && currentItem)|| (edit && currentItem.parent_id)">
                <a-radio-group v-model="data.note_type" @change="changeNoteType">
                    <a-radio :value="1">通用</a-radio>
                    <a-radio :value="2">自定义
                        <a-input v-model="data.note_content" v-if="data.note_type === 2" :style="{ width: '180px', marginLeft: '10px' }" />
                    </a-radio>
                </a-radio-group>
            </a-form-item> -->
        </a-form>
    </a-modal>
</template>
<script>
import * as http from '@/libs/examapi'
export default {
    name: 'addProblem',
    props: [ 'visible', 'currentItem', 'sid', 'edit', 'testList'],
    watch:{
        visible(val){
            if(val){
                this.reset()
                if(this.edit){
                    if(this.currentItem.parent_id){
                        let {paper_name, paper_url} = this.currentItem
                        let url = this.testList.filter(el=>el.id == paper_url).length? Number(paper_url) : null
                        this.data= { paper_name, paper_url: url}
                    }else{
                        this.data.paper_name = this.currentItem.paper_name
                    }
                   
                }
            }
        }
    },
    data(){
        return{
            formItemLayout: {
                labelCol: { span: 4 },
                wrapperCol: { span:18 },
            },
            loading:false,
            data:{
                paper_name: '',
                paper_url: null,
            }
        }
    },
    methods:{
        reset(){
            this.data = {
                paper_name: '',
                paper_url: null,
            }
            this.loading = false
        },
        submit(){
            let {paper_name, paper_url} = this.data
            if(!paper_name){
                this.$message.error(this.currentItem?'请输入试卷名称':'请输入题库名称')
                return
            }
            if((!this.edit && this.currentItem)|| (this.edit && this.currentItem.parent_id)){
                if(!paper_url){
                    this.$message.error('请输入试卷链接')
                    return
                }
            }
            this.loading = true
            let request = null

            if((!this.edit && this.currentItem)|| (this.edit && this.currentItem.parent_id)){
                //子集
                request = this.data
                if(!this.edit){
                    request.sid = this.sid
                }
                request.id = this.currentItem.id
            }else{
                //父级
                if(this.edit){
                    request={
                        id:this.currentItem.id,
                        paper_name
                    }
                }else{
                    request = {
                        sid:this.sid,
                        paper_name
                    }
                }
            }
            if(this.edit){
                http.edit_problems(request).then(res=>{
                    this.$emit('close')
                    this.$emit('update')
                    this.loading = false
                }).catch(error=>{
                    console.log(error)
                    this.loading = false
                })
            }else{
                http.add_problems(request).then(res=>{
                    this.$emit('close')
                    this.$emit('update')
                    this.loading = false
                }).catch(error=>{
                    console.log(error)
                    this.loading = false
                })
            }
        }
    }
}
</script>
<style lang="less" scoped>

</style>
