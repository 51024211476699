<template>
     <a-modal
        :title="'编辑科目'"
        :visible="visible"
        @cancel="$emit('close')"
        destroyOnClose
        :footer="null"
    >
     <div class="tags">
      <template v-for="tag in currentTags">
         <a-popconfirm
            title="确定删除?"
            @confirm="confirm(tag.id)"
            :key="tag.id"
          >
            <div class="z_tag">{{tag.name}}<a-icon type="close" class="z_close_tag" /></div>
         </a-popconfirm>
       
      </template>
      <a-input
        v-if="inputVisible"
        ref="input"
        type="text"
        :style="{ width: '110px' }"
        :value="inputValue"
        @change="handleInputChange"
        @blur="handleInputConfirm"
        @keyup.enter="handleInputConfirm"
      />
    <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">
      <a-icon type="plus" />添加新科目
    </a-tag>
  </div>
    </a-modal>
</template>
<script>
import * as http from '@/libs/examapi'
export default {
    name: 'editsubject',
    props: [ 'visible', 'tags', 'parentId'],
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    watch:{
      visible(val){
        if(val){
          this.currentTags =  this._.cloneDeep(this.tags)
        }
      }
    },
    data(){
        return{
            inputVisible: false,
            inputValue: '',
            currentTags:[]
        }
    },
    methods:{
        confirm(id){
          http.del_subject({id}).then(res=>{
            let tags = this.currentTags.filter(el=> el.id != id)
            this.currentTags = tags
          }).catch(error=>{
            console.log(error)
          })
        },
        showInput() {
          this.inputVisible = true;
          this.$nextTick(function() {
            this.$refs.input.focus();
          });
        },
        handleInputChange(e) {
          this.inputValue = e.target.value;
        },
        handleInputConfirm() {
          if (this.inputValue && !this.currentTags.filter(el=>el.name == this.inputValue).length) {
            http.add_subject({name:this.inputValue, cate_id: this.parentId}).then(res=>{
              let {id, name} = res
              this.currentTags.push({id:Number(id), name})
              Object.assign(this, {
                inputVisible: false,
                inputValue: '',
              });
              
            }).catch(error=>{
              console.log(error)
            })
          }else{
            if(this.inputValue){
              this.$message.error('此分类已存在')
            }else{
              this.inputVisible = false
            }
          }
          
        },
    }
}
</script>
<style lang="less" scoped>
.tags .z_tag {
    margin-bottom: 10px;
    height: 32px;
    line-height: 32px;
}
.z_tag{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    display: inline-block;
    height: 22px;
    margin-right: 8px;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    opacity: 1;
    -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.z_close_tag{
  font-size: 12px;
  display: inline-block;
  transform: scale(0.83333333) rotate(0deg);
  margin-left: 3px;
}
</style>
