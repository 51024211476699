<template>
    <div class="pd20x flex1">
        <addProblem :testList="testList" :visible="openProblem" :currentItem="currentItem" :edit="edit" :sid="currentIndex==0 || !tags.length?0:tags[tabIndex].id" @close="openProblem = false" @update="getProblems"></addProblem>
        <addClass :visible="openClass" :currentItem="currentItem" :edit="edit" :sid="currentIndex==0 || !tags.length?0:tags[tabIndex].id" @close="openClass = false" @update="getCourse"></addClass>
        <editSubject :visible="addVisible" @close="addVisible = false;getTags(subjectList[currentIndex].id);tabIndex = 0" :tags="tags" :parentId="subjectList.length?subjectList[currentIndex].id:null" ></editSubject>
        <div class="h100pct ofA">
            <div class="groups">
                 <a-select :defaultValue="1" class="mr10px" style="width: 180px" @change="changeClass">
                    <a-select-option v-for="item in classB" :key="item.id" >{{item.name}}</a-select-option>
                </a-select>
                <a-cascader ref="cascader" style="width:350px" class="mr10px" :options="subjectList" :allowClear="false" :fieldNames="{label:'cname',value:'id', children:'son'}" placeholder="请选择科目" @change="onChange" />
                <div class="radioGroup">
                    <div class="radioItem" v-for="(item, index) in typeList" :key="index" :class="{active: index == typeIndex}" @click="changeType(index)">{{item.label}}</div>
                </div>
            </div>
            <div v-if="tagsId">
                <basicSet :id="tagsId" v-if="typeIndex == 0"></basicSet>
                <questionSet :id="tagsId" v-else-if="typeIndex == 1"></questionSet>
                <video-list :id="tagsId" :type="typeList[typeIndex].type" v-else-if="typeIndex == 2" />
                <questionList :id="tagsId" :type="typeList[typeIndex].type" v-else></questionList>
            </div>
            <div v-else class="nodata">
                <a-empty :description="'请先选择科目'"/>
            </div>
        </div>
    </div>
</template>
<script>
import editSubject from './editSubject'
import addClass from './subject/addClass'
import addProblem from './subject/addProblem'
import * as http from '@/libs/examapi'
import { getTaskList, getclasses } from '@/libs/api'
import basicSet from './subject/basicSet'
import questionList from './subject/questionList'
import questionSet from './subject/questionSet'
import { classB } from '@/libs/transform'
import VideoList from './subject/videoList.vue'
export default {
    name: 'subject',
    components:{ editSubject, addClass, addProblem, basicSet, questionSet, questionList, VideoList},
    data(){
        return{
            classB,
            subjectList:[],

            tabIndex: 0,
            currentIndex: 0,
            result: [],
            selectedRowKeys: [],
            areaval: '',
            addVisible:false,
            tags: [],
            openClass: false,
            currentItem: null,
            edit:false,
            type: '0',
            openProblem: false,
            testList: [],
            showProfessional:false,

            tagsId: undefined,
            typeList:[
                {label:'基础设置',value:1},
                {label:'题库设置',value:2},
                {label:'领航助学',value:5, type: 3},
                {label:'真题汇编',value:3, type: 1},
                {label:'考前模拟',value:7, type: 5},
                {label:'十套通关',value:4, type: 2},
                {label:'斩题密训',value:6, type: 4}
            ],
            typeIndex: 0,
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.changeClass(1)
        })
    },
    methods:{
        changeClass(val){
            this.$refs.cascader.sValue = []
            this.subjectList = []
            getclasses({id:val}).then(res=>{
                if(res.length){
                    this.subjectList = res
                    this.dealArray(this.subjectList)
                }
            },error=>{
                this.loading = false
            })
        },
        dealArray(data){
            data.forEach(el => {
                if(el.son && el.son.length){
                    this.dealArray(el.son)
                }else{
                    delete el.son
                }
            })
        },
        changeType(index){
            this.typeIndex = index
        },
        getTags(id){
           return new Promise((resolve,reject)=>{
               http.get_subject({cate_id:id}).then(res=>{
                    if(res.length){
                        this.tags = res
                    }else{
                        this.tags = []
                    }
                    resolve()
                }).catch(error=>{
                    reject
                    console.log(error)
                })
           }) 
        },
        getCourse(){
            this.result = []
            if(this.currentIndex == 0 || this.tags.length){
                http.get_course({sid: this.currentIndex==0?0:this.tags[this.tabIndex].id}).then(res=>{
                    if(res.length){
                        res.forEach(el=>{
                            el.expend = true
                        })
                        this.result = res
                    }else{
                        this.result = []
                    }
                }).catch(error=>{
                    console.log(error)
                })
            }
        },
        getProblems(){
            this.result = []
            if(this.currentIndex == 0 || this.tags.length){
                http.get_problems({sid: this.currentIndex==0?0:this.tags[this.tabIndex].id}).then(res=>{
                    if(res.length){
                        res.forEach(el=>{
                            el.expend = true
                        })
                        this.result = res
                    }else{
                        this.result = []
                    }
                }).catch(error=>{
                    console.log(error)
                })
            }
        },
        getCates(){
            http.get_cate().then(res=>{
                this.dealArr(res)
                this.subjectList = res
            }).catch(error=>{
                console.log(error)
            })
        },
        dealArr(data){
            data.forEach(el => {
                if(el.son.length){
                    this.dealArr(el.son)
                }else{
                    delete el.son
                }
            })
        },
        handleChange(){
            if(this.type == 0){
                this.getCourse()
            }else{
                this.getProblems()
            }
        },
        deleteItem(id){
            let _this = this
             this.$confirm({
                title: '是否确定删除该条信息?',
                centered: true,
                onOk() {
                    if(_this.type == 0){
                        http.del_course({id}).then(res=>{
                            _this.getCourse()
                        }).catch(error=>{
                            console.log(error)
                        })
                    }else{
                        http.del_problems({id}).then(res=>{
                            _this.getProblems()
                        }).catch(error=>{
                            console.log(error)
                        })
                    }
                },
            });
        },
        log(){
            let result = { ids:this.result.map(el=>el.id).join(',')}
            if(this.type == 0){
                http.order_course(result).then(res=>{
                }).catch(error=>{
                    this.$message.error('拖拽失败，请重新操作')
                    this.getData()
                    console.log(error)
                })
            }else{
                http.order_Problems(result).then(res=>{
                }).catch(error=>{
                    this.$message.error('拖拽失败，请重新操作')
                    this.getProblems()
                    console.log(error)
                })
            }
        },
        changePlus(index){
           this.result[index].expend = !this.result[index].expend
        },
        changeTab(index){
            this.tabIndex = index;
            this.type == 0 ? this.getCourse() : this.getProblems()
        },
        onChange(val){
            let arr = [...val]
            this.tagsId = arr.pop()
        },
        changTag(val){
            this.tagsId = val
        }
    }
}
</script>
<style lang="less" scoped>
.addBtn{ position: absolute; right: 20px; bottom: 10px; }
.plusIcon{ font-size: 20px; margin-right: 20px; color: #333; vertical-align: middle; cursor: pointer; }
.groups{ background: #fff; padding: 10px 20px; }
.dragWrap{ border-radius: 6px; overflow: hidden; margin-bottom: 5px; }
.dragBody.border{ border-radius: 0; margin-bottom: 2px; }
.radioGroup .radioItem{ margin-top: 10px; margin-bottom: 10px; border-radius: 4px; }
.radioGroup .radioItem.active{ background: #ff5f4e; border-bottom: 1px; color: #fff;}
.nodata{
    text-align: center;
}
</style>